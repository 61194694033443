import React from 'react';
import {Form, List, Icon, Label,Header, ListItem, Button, Modal, Accordion} from 'semantic-ui-react';
import QInstaller from './QInstaller';
import QHelpGuide from './QHelpGuide';
import axios from 'axios';

import  '../css/QDownloads.css'; 
// import logo from  '../images/boydlogo.png';
import { Fragment } from 'react';

// main root class for app
class QDownloadsApp extends React.Component {

    constructor(props) {
    super(props);
    this.state = { userName: null, isLogged:false, companyName:null, latest_downloads:[], beta_downloads:[], old_downloads:[], 
      email: null, showModalDialog:false, receiveUpdate:true, latestActiveState: 0, betaActiveState: -1, oldActiveState: -1 }
    this.tokenTimeOut = null;
    }

    // To DO: - in constructor load access_token from browser and validate it before showing the login or installer request

    // get User Password request form 
    getUserForm(){
    let body = new FormData();
    body.append("username", this.state.email);
    body.append("password", this.state.userName);
    return body;
    }


    // handle company key and email submit button click
    submitHandler = (event) => {
    let result = axios.post('/user/login', this.getUserForm())

    result.then((response) => {this.saveToken(response.data.access_token, response.data.token_validity * 1000)},
                (error) => alert(error.response.data.detail))  // Server error response

    }

    // Use UNSAFE word before react life cycle method from version 16.x (https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html)
    // This method is called during componnet construction. Check for valid saved browser token in the browser local storage and get info from server.
    componentDidMount() {   
      if (this.getToken()){
        let tokenValidity = JSON.parse(localStorage.getItem("authenticationToken"))["expiryTime"] - new Date().getTime()
        if (tokenValidity > 0) {
        this.tokenTimeOut = setTimeout(() => {this.clearToken()}, tokenValidity)
        }
        // this.setState({isLogged : true});
        this.getUser();
        this.getDownloads();
        
      }
    }

    // Note: -Use UNSAFE word before react life cycle method from next version 17.x (https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html)
    // This method is called when any component props or state is changed.
    componentDidUpdate(prevProps, prevState) {
      // if isLogged is changed  and it is true, get organisation and available downloads
      if (this.state.isLogged !== prevState.isLogged) {
        if (this.state.isLogged && this.getToken())
          { this.getUser();
            this.getDownloads();
          }
      }
    }

    saveToken = (token, tokenValidity) => {  // Save the token in the browser local storage
      const expiryTime = new Date().getTime() + tokenValidity
      let tokenData =  {"token":token, "expiryTime":expiryTime}
      localStorage.setItem("authenticationToken",  JSON.stringify(tokenData))
      this.tokenTimeOut = setTimeout(() => {this.clearToken()}, tokenValidity)
      this.setState({isLogged: true})
    }

    getToken () {  // Get token from the local storage.
      try {
      let tokenData = JSON.parse(localStorage.getItem("authenticationToken"))
      return tokenData ? tokenData["token"] : null
      } 
      catch(err) {  // Handle JOSN.parse error with old token string
        this.clearToken()
        return null
      }
    }

    clearToken = () => {  // clear token in the local storage
      localStorage.setItem("authenticationToken", null);
      this.setState({isLogged: false})
      if (this.tokenTimeOut){
        clearTimeout(this.tokenTimeOut)
      }
    }

    // Method to get user organisation name and email from the server
    getUser = () => {
      if (!this.getToken()) {return}
      let result = axios({method: "get", url: "/user/current", params: {token: this.getToken()}})
      result.then((response) => {this.setState({companyName: response.data.organisation, email:response.data.email, receiveUpdate:response.data.receive_update, isLogged:true})},
                  (error) => {this.clearToken(); console.log(error.response.data.detail)})
    }

    // Method to get downloads packages and versions from the server 
    getDownloads = () => {
      if (!this.getToken()) {return}
      let result = axios({method: "get", url: "/files/all", params: {token: this.getToken()}})
      result.then((response)=> {this.setState({latest_downloads: response.data.latest_downloads, beta_downloads: response.data.beta_downloads, old_downloads: response.data.old_downloads})},
                  (error) => {this.clearToken(); console.log(error.response.data.detail)})
    }

    changeModalState = (newStatus) => {
      this.setState({showModalDialog:newStatus})
    }

    changeReciveUpdate = (checkedState) => {
      console.log(checkedState)
      this.setState({receiveUpdate:checkedState})
      const url = "/user/edit_receive_update"
      const params = {receive_update:checkedState, token:this.getToken()}
      let result = axios({method: "put", url:url, params: params})
      result.then((response)=> {},
                    (error) => {this.clearToken(); console.log(error.response.data.detail)})

    }

    render() {

        if (!this.state.isLogged)
        {
        return (    // Render Login page to submit company key and email
          <div className="box">
              {/*<Image src={logo} centered size='small' />*/}
            <Form>
              <Form.Field >
                <label>Email</label>
                <input placeholder='Email' onChange={(event) => this.setState({email: event.target.value})} />
              </Form.Field>
              <Form.Field>
                <label >Company key <QHelpGuide value={<Fragment>If you do not have a company key, please contact <a className="hrefLink" href="mailto:support@smartcfd.freshdesk.com?subject=Company key request">support@smartcfd.freshdesk.com</a> with your full name and company information.</Fragment>}></QHelpGuide></label> 
                <input type="password" placeholder='Company key' title="If you do not have a company key, please contact support@smartcfd.freshdesk.com with your full name and company information." onChange={(event) => this.setState({userName: event.target.value})}/>
              </Form.Field>
              <Button className="submitButton" fluid onClick={(e) => this.submitHandler()}>
                Submit
              </Button>
            </Form>
          </div>
        )}
        else
        {
        
          const companyLabel = this.state.companyName;
          const email =   this.state.email
          const latest_downloads = this.state.latest_downloads;
          const beta_downloads = this.state.beta_downloads;
          const old_downloads = this.state.old_downloads;

        return ( // Installer request form  
          <>
          <div>
          <Modal
              closeIcon
              open={this.state.showModalDialog}
              size='small'
              onClose={() => this.setState({showModalDialog:false})}
              onOpen={() => this.setState({showModalDialog:true})}
            >
              <Modal.Header className="modalHeader">Installer download link</Modal.Header>
              <Modal.Content className="modalContent">
                <p>A Download link valid for 1 day has been sent to your email.</p>
                <p>If you have not received the download link email in your inbox, please check your spam/junk folder 
                and add <a className="hrefLink" href="mailto:support@smartcfd.freshdesk.com">smartcfd.support@boydcorp.com</a> to your contacts.
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() =>this.setState({showModalDialog:false})}>OK</Button>
              </Modal.Actions>
            </Modal>
            </div>
          <div className="box">
            <Button className="logoutButton"   onClick={(e) => this.clearToken()} >Logout</Button>
             {/*<Image src={logo} centered size='small' />*/}
            <List className="list">
              <ListItem>
              <Label className="installerLabel">
                <Icon name='mail' /> {email}
                </Label>
              </ListItem>
              <ListItem>
              <Label className="installerLabel">
                <Icon  name='address card outline' /> {companyLabel}
                </Label>
              </ListItem>
            </List>
            <Header size='medium'>Available installers <QHelpGuide value={<Fragment>Click on the "Request installer link" button corresponding to the version you wish to download. A download link valid for 1 day will be sent to your email address.</Fragment>}></QHelpGuide></Header>
            
            { latest_downloads.length > 0 ? (
              <Accordion>
                < Accordion.Title
                  active={this.state.latestActiveState === 0}
                  content={(
                    <label className="latestDownloadHeader" textAlign='left'>
                      {latest_downloads.length > 1 ? 'Latest Releases' : 'Latest Release'}
                    </label>
                  )}
                  index={0}
                  onClick={(e, titleProps) => this.setState({ latestActiveState : this.state.latestActiveState === titleProps.index ? -1 : 0}) }
                />
                <Accordion.Content style={{marginBottom: 0}} active={this.state.latestActiveState === 0}
                  content = {(
                    <List className="list">
                       {latest_downloads.map(downloadLink => (
                        <ListItem className="listItem" key={downloadLink} >
                        <QInstaller 
                        value={downloadLink} 
                        email={this.state.email} 
                        changeModalState={this.changeModalState}
                        emailSuffix={this.state.emailSuffix} 
                        clearToken={this.clearToken}
                        getToken={this.getToken} >          
                        </QInstaller>
                        </ListItem>
                    ))}
                  </List>
                  )}
                />
              </Accordion>
              ) : ( <></> )
            }

            { beta_downloads.length > 0 ? (
              <Accordion>
                < Accordion.Title
                  active={this.state.betaActiveState === 0}
                  content={(
                    <label className="downloadHeader" textAlign='left'>
                      {beta_downloads.length > 1 ? 'Beta Releases' : 'Beta Release' }
                    </label>
                  )}
                  index={0}
                  onClick={(e, titleProps) => this.setState({ betaActiveState : this.state.betaActiveState === titleProps.index ? -1 : 0}) }
                />
                <Accordion.Content style={{marginBottom: 0}} active={this.state.betaActiveState === 0}
                  content = {(
                    <List className="list">
                      {beta_downloads.map(downloadLink => (
                        <ListItem className="listItem" key={downloadLink} >
                        <QInstaller 
                        value={downloadLink} 
                        email={this.state.email} 
                        changeModalState={this.changeModalState}
                        emailSuffix={this.state.emailSuffix} 
                        clearToken={this.clearToken}
                        getToken={this.getToken} >          
                        </QInstaller>
                        </ListItem>
                    ))}
                  </List>
                  )}
                />
              </Accordion>
              ) : ( <></> )
            } 

            { old_downloads.length > 0 ? (
              <Accordion>
                < Accordion.Title
                  active={this.state.oldActiveState === 0}
                  content={(
                    <label className="downloadHeader" textAlign='left'>
                      { old_downloads.length > 1 ? 'Older Releases' : 'Older Release' }
                    </label>
                  )}
                  index={0}
                  onClick={(e, titleProps) => this.setState({ oldActiveState : this.state.oldActiveState === titleProps.index ? -1 : 0}) }
                />
                <Accordion.Content style={{marginBottom: 0}} active={this.state.oldActiveState === 0}
                  content = {(
                    <List className="list">
                       {old_downloads.map(downloadLink => (
                        <ListItem className="listItem" key={downloadLink} >
                        <QInstaller 
                        value={downloadLink} 
                        email={this.state.email} 
                        changeModalState={this.changeModalState}
                        emailSuffix={this.state.emailSuffix} 
                        clearToken={this.clearToken}
                        getToken={this.getToken} >          
                        </QInstaller>
                        </ListItem>
                    ))}
                  </List>
                  )}
                />
              </Accordion>
              ) : ( <></> )
            }

             
          </div>
          </>
        )}
        
    }
}


export default  QDownloadsApp;
