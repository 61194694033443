import React from 'react';
import {Form, Label, Segment, Accordion, TextArea, Button, Dropdown} from 'semantic-ui-react';
import axios from 'axios';
import '../css/QAdminAPI.css'


class QDeleteOrganization extends React.Component {

    constructor(props) {
        super(props);
        this.state = {organization:null, response:null}
        }

    // Sent download link to the user email.
  deleteOrganization = () => {
    if (!this.state.organization){ 
    alert("Please enter an organization.")
    return
    }
    this.setState({response: ""})
    const url = "/admin/organization"
    const params = {organization: this.state.organization, token: this.props.getToken()}
    let result = axios({method: "delete", url: url, params: params})
    result.then((response)=> {this.setState({response: response.data.response}); this.props.getOrganizations()},
                 (error) => {this.setState({response: error.response.data.detail}); this.props.getUser()})

  }

    render () {
        return (
            <Segment  className='deleteSegmentStyle'>
            <Accordion.Title 
            active={this.props.activeIndex === this.props.index}
            content= {(
                <>
                <Label className='methodLabel' color='red'> Delete an existing organization </Label>
                </>
                )} 
                index={this.props.index}
                onClick={this.props.handleClick}
            />
            <Accordion.Content
            active={this.props.activeIndex === this.props.index} 
            content={(
            <>
            <Form>
                <Form.Field >
                <label>Organization</label>
                <Dropdown placeholder='Select organization' selection options={this.props.organizationNames}
                onChange={(event, {name, value}) => this.setState({organization: value})}/>
                </Form.Field>
                <Form.Field>
                <Button className="submitBtn" fluid onClick={(e) => this.deleteOrganization()}>
                Submit
                </Button>
                </Form.Field> 
                <Form.Field>
                <label>Response</label>
                <TextArea value={this.state.response}/>
                </Form.Field>     
            </Form>
            </>
            )} />
            </Segment>
        )
    }

}

export default QDeleteOrganization