import React from 'react';
import { Table, Icon, Accordion} from 'semantic-ui-react';
import QShowKey from './QShowKey';
import QSendOrganizationEmail from './QSendOrganizationEmail';

const cellStyle = {
    border: "1px solid #a3a3a3"
}

const headerCellStyle = {
    border: "1px solid #a3a3a3",
    backgroundColor: "#cce2ff"
}

class QOrganizationTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = index === activeIndex ? -1 : 0
        this.setState({ activeIndex: newIndex })
    }

render(){
    return(
        <Accordion>
            <Accordion.Title
                active={this.state.activeIndex === 0}
                content={<label className="tableHeader" textAlign='left'>Organizations</label>}
                index={0}
                onClick={this.handleClick}
            />
            <Accordion.Content 
                style={{maxWidth: '100%'}} 
                active={this.state.activeIndex === 0}
                content={
                    <Table celled structured>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={headerCellStyle} textAlign='center'>Organization</Table.HeaderCell>
                                <Table.HeaderCell style={headerCellStyle} textAlign='center'>Role</Table.HeaderCell>
                                <Table.HeaderCell style={headerCellStyle} textAlign='center'>Active</Table.HeaderCell>
                                <Table.HeaderCell style={headerCellStyle} textAlign='center' >Product</Table.HeaderCell>
                                <Table.HeaderCell style={headerCellStyle} textAlign='center'>Max version</Table.HeaderCell>
                                <Table.HeaderCell style={headerCellStyle} textAlign='center'>Preview access</Table.HeaderCell>
                                <Table.HeaderCell style={headerCellStyle} textAlign='center'>Action</Table.HeaderCell>
                                <Table.HeaderCell style={headerCellStyle} textAlign='center'>Key</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.props.organizations.map(org => (
                                <>
                                    <Table.Row>
                                        {org[3].length > 0 ? 
                                            (<>
                                            <Table.Cell style={cellStyle} rowSpan={org[3].length.toString()}>{org[0]}</Table.Cell>
                                            <Table.Cell style={cellStyle} rowSpan={org[3].length.toString()}>{org[1]}</Table.Cell>
                                            <Table.Cell style={cellStyle} rowSpan={org[3].length.toString()} textAlign='center'>
                                                    {org[2] === true ? <Icon color='green' name='checkmark' size='large' />: 
                                                    <Icon color='red' name='close' size='large' />}
                                            </Table.Cell>
                                            <Table.Cell style={cellStyle}>{org[3][0][0]}</Table.Cell>
                                            <Table.Cell style={cellStyle}>{org[3][0][1]}</Table.Cell>
                                            <Table.Cell style={cellStyle} textAlign='center'>
                                                {org[3][0][2]=== true ? <Icon color='green' name='checkmark' size='large' />: 
                                                    <Icon color='red' name='close' size='large' />}
                                            </Table.Cell>
                                            <Table.Cell style={cellStyle} rowSpan={org[3].length.toString()}>
                                                <QSendOrganizationEmail orgName={org[0]} getToken={this.props.getToken} getUser={this.props.getUser} getOrgEmailSuffixes={this.props.getOrgEmailSuffixes}/>
                                            </Table.Cell>
                                            <Table.Cell style={cellStyle} rowSpan={org[3].length.toString()}>
                                                    <QShowKey orgName={org[0]} getToken={this.props.getToken} getUser={this.props.getUser}/>
                                            </Table.Cell>
                                            </>) :
                                            (<>
                                            <Table.Cell style={cellStyle}>{org[0]}</Table.Cell>
                                            <Table.Cell style={cellStyle}>{org[1]}</Table.Cell>
                                            <Table.Cell style={cellStyle} textAlign='center'>
                                                {org[2] ===true ? <Icon color='green' name='checkmark' size='large' />: 
                                                    <Icon color='red' name='close' size='large' />}
                                            </Table.Cell>
                                            <Table.Cell style={cellStyle}></Table.Cell>
                                            <Table.Cell style={cellStyle}></Table.Cell>
                                            <Table.Cell style={cellStyle}></Table.Cell>
                                            <Table.Cell style={cellStyle}>
                                                <QSendOrganizationEmail orgName={org[0]} getToken={this.props.getToken} getUser={this.props.getUser} getOrgEmailSuffixes={this.props.getOrgEmailSuffixes}/>
                                            </Table.Cell>
                                            <Table.Cell style={cellStyle}>
                                                <QShowKey orgName={org[0]} getToken={this.props.getToken} getUser={this.props.getUser}/>
                                            </Table.Cell>
                                            </>)
                                        }
                                    </Table.Row>
                        
                                    {org[3].map((prod, idx) =>(
                                        <>
                                        {idx > 0 ?
                                        (<Table.Row>
                                            <Table.Cell style={cellStyle}>{prod[0]}</Table.Cell>
                                            <Table.Cell style={cellStyle}>{prod[1]}</Table.Cell>
                                            <Table.Cell style={cellStyle} textAlign='center'>
                                                {prod[2]=== true ? <Icon color='green' name='checkmark' size='large' />: 
                                                    <Icon color='red' name='close' size='large' />}
                                            </Table.Cell>
                                        </Table.Row>):(<></>)}
                                        </>    
                                    ))}       
                                </>   
                            ))}
                        </Table.Body>
                    </Table>
                }
            />
        </Accordion>
    )}
}

export default QOrganizationTable