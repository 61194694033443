import React from 'react';
import {Button, Label} from 'semantic-ui-react';
import axios from 'axios';

import '../css/QDownloads.css'; 

// class to render list of available installer to download
class QInstaller extends React.Component {

  constructor(props) {
    super(props);
    this.state = {disable:false}
}

  // Sent download link to the user email.
  onRequestLink = () => {

    if (!this.props.email)
    {
    alert("Please enter email.")
    return
    }
    this.setState({disable: true})
    const url = "/link/" + this.props.value // /link/Qfin/6.2
    let result = axios({method: "get", url:url, params: {token: this.props.getToken()}})
    result.then((response)=> {this.props.changeModalState(true); this.setState({disable: false})},
                 (error) => {alert(error.response.data.detail); this.props.clearToken(); this.setState({disable: false})})

  }
  
  render() { 
    let downloadPath = this.props.value.split("/")  // ['SmartCFD', '6.2.1', 'SmartCFD6Setup-20200902-6.2.1.11016.exe']
    const product = downloadPath[0];  // 'SmartCFD'
    var setupName = downloadPath[2]; // 'SmartCFD6Setup-20200902-6.2.1.11016.exe'
    setupName = setupName.substring(0, setupName.lastIndexOf('.') );  // 'SmartCFD6Setup-20200902-6.2.1.11016'
    let versionBuild = setupName.split("-"); // ['SmartCFD6Setup', '20200902', '6.2.1.11016']
    var buildDate = versionBuild[1]; // '20200902'
    buildDate = "[" + buildDate.substring(0, 4) + "-" + buildDate.substring(4, 6) + "-" + buildDate.substring(6, 8) + "]"; // '[2020-09-02]'
    var buildName;
    if (versionBuild.length > 3) {
      buildName = versionBuild[2] + "-" + versionBuild[3]; // '6.2.9058-preview'
    }  
    else {
      buildName = versionBuild[2];  // '6.2.1.11016'
    }
    buildName = buildName + " " + buildDate; // '6.2.1.11016 [2020-09-02]'

    const installerLabel = product + " " + buildName  // e.g "SmartCFD 6.2.1.11016 [2020-09-02]" or  "Qfin 6.2.9058-preview [2020-09-02]"
    return (  
      <div>
      <Label className="installerLabel1">
          {installerLabel}
      </Label>
      <Button className="installerButton" disabled={this.state.disable} title="Click on this button to get a download link sent to your email address." onClick={(e) => this.onRequestLink()}>
      Request download link
      </Button>
      </div>
    );
  }
}
 
export default QInstaller;
