import React from 'react';
import {Form, Label, Segment, Accordion, TextArea, Button, Dropdown, FormField, Progress} from 'semantic-ui-react';
import axios from 'axios';
import '../css/QAdminAPI.css'

const previewOptions = [
    { key: 'false', text: 'False', value:'false'},
    { key: 'true', text: 'True', value:'true'},   
]

class QAddProductRelease extends React.Component {

    constructor(props) {
        super(props);
        this.state = {product:null, version:null, installerFile:null, response:null, disable:false, preview:false, percentage:0.0}
        }

    onFileChange = (event) => { 
        this.setState({ installerFile: event.target.files[0] });    
        }; 

    // Sent download link to the user email.
    addProductRelease = () => {
        if (!this.state.product){ 
            alert("Please enter a product.")
            return
        }
        if (!this.state.version){ 
            alert("Please define a version.")
            return
            }
        if (!this.state.installerFile){ 
        alert("Please enter an installer file.")
        return
        }
        this.setState({response: "", disable: true})
        const formData = new FormData(); 
        formData.append("file", this.state.installerFile)
        
        const config =  {
            onUploadProgress: (progressEvent) => {
              var percentCompleted = Math.round((progressEvent.loaded * 100.0) / progressEvent.total)
              console.log(percentCompleted) 
              this.setState({percent: percentCompleted}) 
            }, params: {token:this.props.getToken(), preview:this.state.preview}
        }

        const url = "/admin/upload/" + this.state.product + "/" + this.state.version
        // const params = {token:this.props.getToken(), preview:this.state.preview}
        // let result = axios({method: "post", url:url, params: params, data:formData})
        let result = axios.post(url, formData, config)
        result.then((response)=> {this.setState({response: response.data.db_response, disable:false, percent:0.0}); this.props.getProducts()},
                    (error) => {this.setState({response: error.response.data.detail, disable:false, percent:0.0}); this.props.getUser()})

    };

    render () {
        return (
            <Segment  className='postSegmentStyle'>
            <Accordion.Title 
            active={this.props.activeIndex === this.props.index}
            content= {(
                <>
                <Label className='methodLabel' color='green'> Upload a given version of a given product </Label>
                </>
                )} 
                index={this.props.index}
                onClick={this.props.handleClick}
            />
            <Accordion.Content
            active={this.props.activeIndex === this.props.index} 
            content={(
            <>
            <Form>
                <Form.Field >
                <label>Product</label>
                <Dropdown placeholder='Select product' selection options={this.props.productNames}
                onChange={(event, {name, value}) => this.setState({product: value})}/>
                </Form.Field>
                <Form.Field >
                <label>version</label>
                <input placeholder='Version'  onChange={(event) => this.setState({version: event.target.value})}/>
                </Form.Field>
                <Form.Field >
                <label>Preview version</label>
                <Dropdown placeholder='Preview'  selection options={previewOptions} defaultValue={previewOptions[0].value} 
                onChange={(event, {className, value}) => this.setState({preview: value})}/>
                </Form.Field>
                <Form.Field >
                <label>Installer file</label>
                <input type="file" onChange={this.onFileChange} /> 
                </Form.Field>
                <Form.Field>
                <Button className="submitBtn" disabled={this.state.disable} fluid onClick={(e) => this.addProductRelease()}>
                Submit
                </Button>
                </Form.Field>
                <FormField>
                    <Progress percent={this.state.percent} indicating progress />
                </FormField> 
                <Form.Field>
                <label>Response</label>
                <TextArea value={this.state.response}/>
                </Form.Field>     
            </Form>
            </>
            )} />
            </Segment>
        )
    }

}

export default QAddProductRelease