import React from 'react';
import { Table, Icon, Image, Modal, Button, Label, Accordion } from 'semantic-ui-react';
import deleteIcon from '../images/delete.png'
import axios from 'axios';

import  '../css/QDownloads.css'; 

const cellStyle = {
    border: "1px solid #a3a3a3",
    textAlign: "center"
}

const headerCellStyle = {
    border: "1px solid #a3a3a3",
    backgroundColor: "#cce2ff"
}

const iconStyle = {
    cursor:"pointer"
}

class QDeleteLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showModalDialog: false }
    }

    handleCancel = () => {
        this.setState({ showModalDialog: false })
    }

    handleConfirm = () => {
        this.setState({ showModalDialog: false })
        const url = "/admin/delete_installer_link"
        const params = {link_key: this.props.linkKey, token: this.props.getToken()}
        let result = axios({method: "delete", url:url, params: params})
        result.then((response)=> { this.props.getInstallerLinks() },
                    (error) => { alert(error.response.data.detail); this.props.getUser() })
    }

    render () {
        return (
            <div>
                <Icon style={iconStyle} size='large' onClick={(e) => this.setState({ showModalDialog: true })}>
                    <Image src={deleteIcon} width={24} height={24}/>
                </Icon>
                <Modal
                    closeIcon
                    open={this.state.showModalDialog}
                    size='small'
                    onClose={ () => this.handleCancel() }
                    onOpen={ () => this.handleConfirm() }
                >
                    <Modal.Header className="modalHeader">
                        <label>Delete installer link</label>
                        <Label className="installerLabel"><Icon  name='linkify' />{this.props.linkKey}</Label>
                    </Modal.Header>
                    <Modal.Content className="modalContent">   
                        <label>Do you want to delete installer link?</label>    
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() =>this.handleCancel()}>Cancel</Button>
                        <Button onClick={() =>this.handleConfirm()}>OK</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }

}

class QShareInstallerLinksTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = index === activeIndex ? -1 : 0
    
        this.setState({ activeIndex: newIndex })
    }

    copyLink = (linkKey) => {
        const link = 'https://www.qfinsoft.com/share-download/' + linkKey;
        navigator.clipboard.writeText(link);
        alert('Installer link copied to the clipboard.');
    }

    render(){
        return(
            <Accordion>
                <Accordion.Title
                    active={this.state.activeIndex === 0}
                    content={(<label className="tableHeader" textAlign='left'>Installer links</label>)}
                    index={0}
                    onClick={this.handleClick}
                />
                <Accordion.Content  style={{maxWidth: '100%'}} active={this.state.activeIndex === 0}
                    content = {(<Table celled structured>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={headerCellStyle} textAlign='center'>Link key</Table.HeaderCell>
                                <Table.HeaderCell style={headerCellStyle} textAlign='center'>Installer</Table.HeaderCell>
                                <Table.HeaderCell style={headerCellStyle} textAlign='center'>Days</Table.HeaderCell>
                                <Table.HeaderCell style={headerCellStyle} textAlign='center'>Expiry (UTC Time)</Table.HeaderCell>
                                <Table.HeaderCell style={headerCellStyle} textAlign='center'>Created by</Table.HeaderCell>
                                <Table.HeaderCell style={headerCellStyle} textAlign='center'>Action</Table.HeaderCell>
                                <Table.HeaderCell style={headerCellStyle} textAlign='center'>Link</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            { this.props.links.length > 0 ? (
                                
                                this.props.links.map(link => (
                                    <Table.Row>
                                        <Table.Cell style={cellStyle}>{link[0]}</Table.Cell>
                                        <Table.Cell style={cellStyle}>{link[1]}</Table.Cell>
                                        <Table.Cell style={cellStyle}>{link[2]}</Table.Cell>
                                        <Table.Cell style={cellStyle}>{link[3]}</Table.Cell>
                                        <Table.Cell style={cellStyle}>{link[4]}</Table.Cell>
                                        <Table.Cell style={cellStyle}>
                                            <QDeleteLink linkKey={link[0]} getToken={this.props.getToken} getInstallerLinks={this.props.getInstallerLinks} getUser={this.props.getUser}/>
                                        </Table.Cell>
                                        <Table.Cell style={cellStyle}>
                                            <Icon style={iconStyle} name='linkify' size='large' title='Click to copy installer link' onClick={(e) => this.copyLink(link[0])}></Icon>
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                            )
                            : 
                            (<></>)
                            }
                        </Table.Body>
                    </Table>)}
                />
            </Accordion>
        
        )
    }
    
}

export default QShareInstallerLinksTable