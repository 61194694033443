import React from 'react';
import {Form, Label, Segment, Accordion, Dropdown, TextArea, Button, List, ListItem} from 'semantic-ui-react';
import axios from 'axios';
import '../css/QAdminAPI.css'


const actionOptions = [
    { key: 'add', text: 'Add new Email suffix(es)', value:'add'},
    { key: 'remove', text: 'Remove Email suffix', value:'remove'},   
]

class QUpdateOrgEmailSuffix extends React.Component {

    constructor(props) {
        super(props);
        this.state = {organization:null, emailSuffix:null, response:null, action:'add', newEmailSuffixes:null}
        }

    // Modify (Add/Remove) organization (compnay) email suffix.
    modifyOrgEmailSuffix = () => {

        if (!this.state.action) {
            alert("Please specify action.")
            return
        }

        if (!this.state.organization){ 
            alert("Please enter an organization.")
        return
        }

        if (this.state.action === "remove") {
            if (!this.state.emailSuffix) {
                alert("Please select an email suffix.")
            }
            this.setState({response: ""})
            const url = "/admin/remove_email_suffix"
            const params = {organization: this.state.organization, email_suffix:this.state.emailSuffix, token:this.props.getToken()}
            let result = axios({method: "put", url:url, params: params})
            result.then((response)=> {this.setState({response: response.data.response}); this.props.getOrganizations()},
                        (error) => {this.setState({response: error.response.data.detail}); this.props.getUser()})
        }
        else {
            if (!this.state.newEmailSuffixes) {
                alert("Please enter email suffix(es)")
                return
            }
            this.setState({response: ""})
            const url = "/admin/add_email_suffix"
            const params = {organization: this.state.organization, email_suffixes: this.state.newEmailSuffixes, token:this.props.getToken()}
            let result = axios({method: "put", url:url, params: params})
            result.then((response)=> {this.setState({response: response.data.response, newEmailSuffixes:""}); this.props.getOrganizations()},
                        (error) => {this.setState({response: error.response.data.detail}); this.props.getUser()})
        }
        
    }

    emailSuffixes = () => {
        return this.props.getOrgEmailSuffixes(this.state.organization)
    }

    render () {
        return (
            <Segment  className='putSegmentStyle'>
            <Accordion.Title 
            active={this.props.activeIndex === this.props.index}
            content= {(
                <>
                <Label className='methodLabel' color='orange'>Modify organization (company) Email Suffix</Label>
                </>
                )} 
                index={this.props.index}
                onClick={this.props.handleClick}
            />
            <Accordion.Content
            active={this.props.activeIndex === this.props.index} 
            content={(
            <>
            <Form>
                <Form.Field >
                    <label>Action</label>
                    <Dropdown placeholder='action'  selection options={actionOptions} defaultValue={this.state.action} 
                    onChange={(event, {name, value}) => this.setState({action: value})}/>
                </Form.Field>
                <Form.Field >
                    <label>Organization</label>
                    <Dropdown placeholder='Select organization' selection options={this.props.organizationNames}
                    onChange={(event, {name, value}) => this.setState({organization: value})}/>
                </Form.Field>

                {this.state.action === 'remove' ? 
                (
                <Form.Field>
                    <label>Email suffix to remove</label>
                    <Dropdown placeholder='Email suffix' selection options={this.emailSuffixes()}
                    onChange={(event, {name, value}) => this.setState({emailSuffix: value})}/>
                </Form.Field>
                ) :
                (
                <>
                <Form.Field>
                    <label>Current Email suffix(es)</label>
                    <List horizontal>
                        {this.emailSuffixes().map(es => <ListItem>{es['key']}</ListItem>)}   
                    </List>
                </Form.Field>
                <Form.Field>
                    <label>Email suffix(es) to add</label>
                    <input value={this.state.newEmailSuffixes} placeholder='For multiple Email suffixes use semicolon(;)'  onChange={(event) => this.setState({newEmailSuffixes: event.target.value})}/>
                </Form.Field>
                </>
                )
                }
                <Form.Field>
                    <Button className="submitBtn" fluid onClick={(e) => this.modifyOrgEmailSuffix()}>
                    Submit
                    </Button>
                </Form.Field> 
                <Form.Field>
                    <label>Response</label>
                    <TextArea value={this.state.response}/>
                </Form.Field>     
            </Form>
            </>
            )} />
            </Segment>
        )
    }

}

export default QUpdateOrgEmailSuffix