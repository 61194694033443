import React from 'react';
import "../css/Home.css"
import "../css/pure-min.css"
import "../css/side-menu-old-ie.css"
import "../css/side-menu.css"
import "../css/FontSize.css"
import "../css/numberheadings.css"

import strc from "../images/Strc.jpg"
import prjMgr from "../images/PrjMgr.jpg"
import compTool from "../images/CompTool.jpg"
import prpt from "../images/Prpt.jpg"
import surfSnap from "../images/SurfSnap.png"
import finalView from "../images/FinalView.jpg"
import heatSS from "../images/HeatSS.jpg"
import solve from "../images/Solve.png"
import report from "../images/Report.png"


const jstfalign = {
    textAlign: "justify",
    lineHeight: "1.6em",
}

const centeralign = {
    textAlign: "center",
    lineHeight: "1.6em",
}

const boxStyle =  {
    maxWidth: "800px",
    margin: "auto",
}

class Home extends React.Component {

    render() {
        return (
         
            <div id="main" style={boxStyle}>
            <div className="header">
                <h1>Welcome to Qfin 6!</h1>
            </div>
                <div className="content"></div> 
                <div>
                    <h2 id="overview" className="content-subhead">Overview</h2>
                    <h3 id="Intro" className="content-subhead">Introduction</h3>

                    <p style={jstfalign}>
                        Qfin is a powerful simulation software tool that allows engineers and scientists to simulate the flow characteristics and thermal performance inside complex systems.
                        One of the most common uses of Qfin is to perform detailed heat transfer and fluid flow simulations in electronic systems that can increase a product's quality and
                        reduce its time-to-market. Qfin provides ease of design with its level of automation, user-friendly design interface and multiple solver options; ranging from an efficient
                        correlation based solver referred to as a System Solver to a detailed CFD (Computational Fluid Dynamics) Solver.
                    </p>
                    <p style={jstfalign}>
                        Qfin is a total thermal management system that can be used to solve component-level, board-level, or system-level applications.
                        It provides design engineers with the ability to test conceptual designs under operating conditions that might be impractical to
                        duplicate with a physical model, and obtain data at locations that might otherwise be inaccessible for monitoring.
                    </p>

                    <h3 id="Strc" className="content-subhead">Qfin structure</h3>
                    <p style={jstfalign}>
                        All of the functions that are required to build a Qfin model, calculate a solution, and examine the results can be accessed in multiple
                        ways through Qfin's interactive user interface. Figure 1 shows different parts of Qfin’s user interface. There are main menus and a toolbar
                        for most common tasks, a component toolbar for adding standard components, and context-sensitive right-click menus for most objects in the Project Manager.
                    </p>

                    <figure>
                    <p style={centeralign}>
                        <img src={strc} style={{width:"800px", height:"460px"}} alt="Qfin structure" align="middle"/>
                    </p>
                    <p style={centeralign}>Fig. 1. Qfin structure.</p>
                    </figure>

                    <p style={jstfalign}> In the following, the different parts of Qfin are briefly described. </p>
                    <p><b>3D Graphics Designer Window</b> – 3D graphical display of the model and its solution. </p>
                    <p><b>Project Manager</b> – Hierarchical representation of all objects in Qfin, including your project and all the components in your model. </p>
                    <p><b>Message Log</b> – Displays informative messages about Qfin’s actions, including warnings and errors. </p>
                    <p><b>Property Panel</b> – The Property Panel can either be docked or floating into the main GUI. </p>
                    <p><b>Menu Bar</b> – Commonly used menu commands are present in the menu bar. </p>
                    <p><b>General Toolbar</b> – Contains toolbars for quick access to the menu commands and general Qfin operations. </p>
                    <p><b>Component Toolbar</b> – All the component types can be added to your project from this toolbar. </p>
                    <p><b>Position and Orient Geometry Toolbar</b> – Contains newly added functionality for Qfin which offers the user dynamic freedom to design their model. </p>
                    <p className="jstfalign">More specifically, Project Manager, Component Toolbar and Property Panel are described with more details in the following subsections. </p>

                    <h4 id="ProjectManager" className="content-subhead">Project Manager</h4>
                    <p style={jstfalign}>
                        The Project Manager is a hierarchical tree-based representation of all the objects that make up a Qfin project, and is the primary vehicle for
                        interacting with Qfin. In fig. 2, you can see a brief description for different parts of the Project Manager.
                    </p>
                    <figure>
                        <p style={centeralign}>
                            <img src={prjMgr} alt="ProjectManager" style={{width:"800px",height:"530px"}} align="middle" />
                        </p>
                        <p style={centeralign}>Fig. 2. A description for different parts of the Project Manager.</p>
                    </figure>
                    <p style={jstfalign}>
                        As components are created and listed in the Project Manager {'>'} Model, they are assigned a priority. The lower down a component is listed in
                        the Project Manager {'>'} Model, the higher its priority will be. The priority becomes significant when two or more components occupy the same
                        space (see subsection 3.2).
                    </p>

                    <h4 id="ComponentToobar" className="content-subhead">Component Toobar</h4>
                    <p style={jstfalign}>
                        Component Toolbar is where you can access to all the components available in Qfin. In fig. 3, you can find a short
                        description of these components.
                    </p>
                    <figure>
                        <p style={centeralign}>
                            <img src={compTool}  style={{width:"800px", height:"570px"}} alt="ComponentToobar" align="middle" />
                        </p>
                        <p style={centeralign}>Fig. 3. The Component Toolbar descriptions.</p>
                    </figure>


                <h4 id="PropertyPanel" className="content-subhead">Property Panel</h4>
                <p style={jstfalign}>
                    The Property Panel for each object can be activated by clicking on the object from the Project Manager.
                    In Property Panel you can edit the properties of the object. The Property Panels of all the components
                    are similar in layout with tabs to define the following:
                </p>
                <p className="tab">&bull; Information of the Component,</p>
                <p className="tab">&bull; The position and size of the component,</p>
                <p className="tab">&bull; The properties of the component,</p>
                <p className="tab">&bull; Interface characteristics of the surfaces of the component,</p>
                <p className="tab">&bull; The local mesh settings for the component,</p>
                <p className="tab">&bull; Visualization setting for the component.</p>
                <p style={jstfalign}>
                    As an example, you can see the Property Panel of a block component in fig. 4. The Property Panel can either
                    float over the main screen or can be docked in the graphics window.
                </p>
                <figure>
                    <p style={centeralign}>
                        <img src={prpt} style={{width:"350px",height:"455px"}} alt="PropertyPanel" align="middle"/>
                    </p>
                    <p style={centeralign}>Fig. 4. Property Panel of a block component.</p>
                </figure>

                <h3 id="SimProc" className="content-subhead">General simulation procedure</h3>
                <p>In this section, we describe the basic procedural steps to run a simulation.</p>


                <h4 id="NewProject" className="content-subhead">Create a new project</h4>
                <p style={jstfalign}>
                    In the first step, you create a new project (or you can open an existing one) and then specifying the environmental
                    condition such as ambient temperature and pressure. You can access to these parameters by clicking the Environment
                    object in Project Manager. You can also change the system unit from the User Preferences object.
                </p>


                <h4 id="SimComp" className="content-subhead">Building the simulation components</h4>
                <p style={jstfalign}>
                    In the next step, you specify the Solution Domain properties, which includes the solution domain size, operating fluid,
                    mesh size and etc.. Once you specified these properties, you can add different components to the Solution Domain from the
                    Component Toolbar. In order to better understand this step, let’s simulate a natural convection problem with a heat sink
                    which absorbs heat from a heat source.
                </p>
                <p style={jstfalign}>
                    Click on the Solution Domain and select the Geometry tab and set d1, d2 and d3 to 400mm.
                    Choose air for the Solution Domain Fluid.
                </p>
                <p style={jstfalign}>
                    Click on the Heat Sink icon in the Component Toolbar and add a new heat sink. Select Geometry tab and set the X0, Y0 and Z0 to
                    150mm (make sure the Global Coordinates is selected for the Coordinate system). You can change the property of a heat sink by
                    clicking on the Profile object below the Heat Sink object in the Project Manager.
                </p>
                <p style={jstfalign}>
                    Click on the Heat source icon in the Component Toolbar and add a new heat source. The heat source should be attached to the bottom
                    surface of the heat sink. To do that, select the heat sink and click on <img src={surfSnap} alt="SnapFace" style={{width:"23px",height:"22px"}}/> icon
                    (the Snap source face to target face (center to center)) from the Position and Orient Geometry Toolbar. Then select the bottom surface of
                    the heat sink. In the new window, select Attach SOURC to TARGET and click on Move SOURCE to TARGET button. You can change the property of
                    the heat sink such as the Total power, Junction Resistances and etc. from the Property Panel.
                </p>
                <p style={jstfalign}>
                    <b>Note:</b> Here, the heat sink and heat source occupy the same space. But, we want the heat source to produce heat at this space and then
                    the heat sink dissipates it, which means that the heat source should have a higher priority. In order to have a higher priority,
                    make sure that the heat source is located below the heat sink in Project Manager {">"} Model as you can see in fig. 5.
                </p>
                <figure>
                    <p style={centeralign}>
                        <img src={heatSS} alt="HeatSinkSource" align="middle" style={{width:"200px", height:"113px"}}/>
                    </p>
                    <p style={centeralign}>Fig. 5. The correct order of heat sink and heat source.</p>
                </figure>
                <p style={jstfalign}>
                    The Solution Domain should be ventilated, otherwise the temperature inside the Solution Domain becomes infinitely large. Click on the Opening
                    icon from the Component Toolbar and add a new opening. Increase the opening dimension to 100mm by 100mm and set (X0,Y0,Z0) to (0,400,0).
                    You can change the other property of the opening from the Prop tab. At this point, your 3D Graphics Designer Window should be look like fig. 6.
                </p>
                <figure>
                    <p style={centeralign}>
                        <img src={finalView} alt="HeatSinkSource" align="middle" style={{width:"800px", height:"464px"}}/>
                    </p>
                    <p style={centeralign}>Fig. 6. The 3D Graphics Designer Window after building simulation components.</p>
                </figure>


                <h4 id="RunSolver" className="content-subhead">Run the solver</h4>
                <p style={jstfalign}>
                    Qfin provides two primary solver options, i.e. the System Solver and CFD Solver, which can be selected from the Project Manager.
                </p>
                <p style={jstfalign}>
                    The System Solver is a hybrid solver which balances numerical efficiency with fluid region detail. This solver implements a control
                    volume discretization and a semi-empirical thermo-fluid network model to calculate the fluid region's flow and temperature properties,
                    while a more detailed finite-volume discretization is used to represent the solid region. Due to the fast convergence of System Solver,
                    it is recommended for the majority of modelling applications, from initial concept assessment to final design.
                </p>
                <p style={jstfalign}>
                    On the other hand, the CFD Solver is a detailed solver which uses a finite volume scheme for the discretization in both the solid and
                    fluid regions. This solver is recommended for use during final design phase.
                </p>
                <p style={jstfalign}>
                    By clicking on each of these solvers, you can modify the solver’s properties such as maximum iterations, record solution frequency, solver type,
                    discretization schemes and so many other parameters.
                </p>
                <p style={jstfalign}>
                    You can choose any of the System Solver or CFD Solver for our natural convection problem, started in subsection 3.2 (Make sure you select the
                    Include gravity in the solver property). Once you selected a solver,
                    click on the Solve icon <img src={solve} alt="Solve" style={{width:"20px", height:"21px"}}/> from the General Toolbar to run the solver.
                </p>

                <h4 id="ExamRes" className="content-subhead">Examining the results</h4>
                <p style={jstfalign}>
                    Once the solving process is finished, the results are presented as vectors and contours for the fluid and solid regions, respectively.
                    You can modify their representation by clicking on the Fluid and Solid objects in the Project Manager.
                </p>
                <p style={jstfalign}>
                    You can also see a summary of the simulation results in a PDF format, by clicking on View PDF report
                    icon <img src={report} alt="SimulationResults" style={{width:"19px", height:"20px"}}/> from the General Toolbar.
                </p>

                <h3 id="MoreDet" className="content-subhead">More details</h3>
                <p style={jstfalign}>
                    For more detailed description of the Qfin software, please refer to Qfin help.
                </p>
                
            </div>
          </div>
         

        );
    }
}


export default  Home;
