import React from 'react';
import {Icon, Modal, Button, Form, Label, Dropdown} from 'semantic-ui-react';
import axios from 'axios';

import  '../css/QDownloads.css'; 

const iconStyle = {
    cursor:"pointer"
}


class QSendOrganizationEmail extends React.Component {
    constructor(props) {
        super(props);
        var emailSuffixes = this.props.getOrgEmailSuffixes(this.props.orgName)
        var defEmailSuffix = emailSuffixes.length > 0 ? emailSuffixes[0].value : null    
        this.state = {toEmail:null, showModalDialog:false, emailSuffix:defEmailSuffix}
    }

    currentEmailSuffixes() {
      return this.props.getOrgEmailSuffixes(this.props.orgName)
    }

    openEMailDialog () {
        this.setState({showModalDialog:true})
    }

    send_email () {

      if (!this.state.toEmail){ 
        alert("Please enter To email address prefixes.")
        return
        }
      if (this.state.toEmail.includes("@")) {
        alert("Please only use the prefix (part before the @) of the email.")
        return
      }
      if (!this.state.emailSuffix){ 
        alert("Please Select organization email suffix.")
        return
        }

        this.setState({showModalDialog:false})
        const url = "/admin/send_org_email" // /link/Qfin/6.2
        const params = {organization: this.props.orgName, email_prefixes:this.state.toEmail, email_suffix:this.state.emailSuffix, token:this.props.getToken()}
        let result = axios({method: "get", url:url, params: params})
            result.then((response)=> {alert("Email Sucessfully sent.")},
                 (error) => {alert("Error happen while sending the email."); this.props.getUser()})

        // this.setState({showModalDialog:true})
    }

    render() { 
        return (
            <> 
            <Icon 
            title="Click to send email to organization with company key and instructions"
            name="mail" size="large"  
            style={iconStyle} 
            onClick={(e) => this.openEMailDialog()}/>
            <div>
            <Modal
              closeIcon
              open={this.state.showModalDialog}
              size='small'
              onClose={() => this.setState({showModalDialog:false})}
              onOpen={() => this.setState({showModalDialog:true})}
            >
              <Modal.Header className="modalHeader">
                <label>Send email to organization user(s)</label>
                <Label className="installerLabel"><Icon  name='address card outline' /> {this.props.orgName}</Label>
              </Modal.Header>
              <Modal.Content className="modalContent">   
              <Form>
              <Form.Field>
                <label>To Email prefix(es) </label>
                <input placeholder='Just use part before the @, e.g. [firstname.lastname]@test.com. For multiple addresses use semicolon(;)' onChange={(event) => this.setState({toEmail: event.target.value})} />
              </Form.Field>
              <Form.Field>
                    <label>Email suffix</label>
                    <Dropdown placeholder='Email suffix' selection options={this.currentEmailSuffixes()} 
                    defaultValue={this.state.emailSuffix}
                    disabled={this.currentEmailSuffixes().length < 2}
                    onChange={(event, {name, value}) => this.setState({emailSuffix: value})}/>
              </Form.Field>
              </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() =>this.send_email()}>Send email</Button>
              </Modal.Actions>
            </Modal>
            </div>
            </>
         );
    }
}
 
export default QSendOrganizationEmail;