import React from 'react';
import {Icon, Modal, Button, Form, Label} from 'semantic-ui-react';
import axios from 'axios';

import  '../css/QDownloads.css'; 

const iconStyle = {
    cursor:"pointer"
}

class QGenerateInstallerLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {toEmail:null, showModalDialog:false, days:1, organization:null}
    }

    generate_installer_link () {
        this.setState({showModalDialog:false})
        const url = "/admin/generate_installer_link" 
        const buildDate = this.props.buildDate;
        const params = { product: this.props.product, version: this.props.version, build_number: this.props.buildNumber, 
                        build_date: buildDate, validity: this.state.days, token: this.props.getToken() }

        let result = axios({method: "get", url:url, params: params})
            result.then((response)=> {this.props.getInstallerLinks(); alert("Installer link sucessfully sent to your login email.")},
                 (error) => {alert(error.response.data.detail); this.props.getUser()})   
    }

    render() { 
        const prodFullName = this.props.product + " " + this.props.version + "." + this.props.buildNumber;
        return (
            <> 
            <Icon 
            title="Click to Generate installer link."
            name="share alternate" size="large"  
            style={iconStyle} 
            onClick={(e) => this.setState({showModalDialog:true})}/>
           
            <Modal
              closeIcon
              open={this.state.showModalDialog}
              size='small'
              onClose={() => this.setState({showModalDialog:false})}
              onOpen={() => this.setState({showModalDialog:true})}
            >
              <Modal.Header className="modalHeader">
                <label>Generate installer link to share</label>
                <Label className="installerLabel"><Icon  name='product hunt' />{prodFullName}</Label>
              </Modal.Header>
              <Modal.Content className="modalContent">   
              <Form>
                <Form.Field>
                  <label>Link validity in day(s)</label>
                  <input defaultValue={this.state.days} onChange={(e) => this.setState({days: e.target.value})}></input>
                </Form.Field>
              </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() =>this.generate_installer_link()}>Generate installer link</Button>
              </Modal.Actions>
            </Modal>
            
            </>
         );
    }
}
 
export default QGenerateInstallerLink;