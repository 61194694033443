import React from 'react';
import {Form, Label, Segment, Accordion, Dropdown, TextArea, Button} from 'semantic-ui-react';
import axios from 'axios';
import '../css/QAdminAPI.css'


const previewOptions = [
    { key: 'false', text: 'False', value:'false'},
    { key: 'true', text: 'True', value:'true'},   
]

class QAddOrgProduct extends React.Component {

    constructor(props) {
        super(props);
        this.state = {organization:null, product:null, maxVersion:null, response:null, preview:false}
        }

    // Sent download link to the user email.
    addOrgProduct = () => {
        if (!this.state.organization){ 
        alert("Please enter an organization.")
        return
        }
        if (!this.state.product){ 
            alert("Please enter a product.")
            return
        }
        if (!this.state.maxVersion){ 
            alert("Please define a max version.")
            return
        }
        this.setState({response: ""})
        const url = "/admin/" + this.state.organization +"/" + this.state.product
        const params = {max_version: this.state.maxVersion, preview: this.state.preview, token:this.props.getToken()}
        let result = axios({method: "put", url:url, params: params})
        result.then((response)=> {this.setState({response: response.data.response}); this.props.getOrganizations()},
                    (error) => {this.setState({response: error.response.data.detail}); this.props.getUser()})

    }

    render () {
        return (
            <Segment  className='putSegmentStyle'>
            <Accordion.Title 
            active={this.props.activeIndex === this.props.index}
            content= {(
                <>
                <Label className='methodLabel' color='orange'> Add/update an organization product access info </Label>
                </>
                )} 
                index={this.props.index}
                onClick={this.props.handleClick}
            />
            <Accordion.Content
            active={this.props.activeIndex === this.props.index} 
            content={(
            <>
            <Form>
                <Form.Field >
                <label>Organization</label>
                <Dropdown placeholder='Select organization' selection options={this.props.organizationNames}
                onChange={(event, {name, value}) => this.setState({organization: value})}/>
                </Form.Field>
                <Form.Field>
                <Form.Field >
                <label>Product</label>
                <Dropdown placeholder='Select product' selection options={this.props.productNames}
                onChange={(event, {name, value}) => this.setState({product: value})}/>
                </Form.Field>
                <Form.Field >
                <label>Max version</label>
                <input placeholder='Max version'  onChange={(event) => this.setState({maxVersion: event.target.value})}/>
                </Form.Field>
                <Form.Field >
                <label>Preview access</label>
                <Dropdown placeholder='Preview'  selection options={previewOptions} defaultValue={previewOptions[0].value} 
                onChange={(event, {className, value}) => this.setState({preview: value})}/>
                </Form.Field>
                <Button className="submitBtn" fluid onClick={(e) => this.addOrgProduct()}>
                Submit
                </Button>
                </Form.Field> 
                <Form.Field>
                <label>Response</label>
                <TextArea value={this.state.response}/>
                </Form.Field>     
            </Form>
            </>
            )} />
            </Segment>
        )
    }

}

export default QAddOrgProduct