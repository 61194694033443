import React from 'react';
import { Icon } from 'semantic-ui-react';
import '../../css/Qshare.css'

class QShareDownloadError extends React.Component  {

    constructor(props) {
        super(props);
        this.state = { productVersion: props.match.params.productVersion };
    }

    render() {
        return (     
            <label className="errorBox">
                <Icon name='exclamation' size='large' /> Invalid link or link expired.
            </label>             
        )
    }

    

}

export default QShareDownloadError;