import React from 'react';
// import { If, Then, ElseIf, Else } from 'react-if-elseif-else-render';
import {Form, Accordion, Header, Button} from 'semantic-ui-react';
// import QInstaller from './QInstaller';
import QOrganizationTable from '../comps/QOrganizationTable';
import QProductTable from '../comps/QProductTable';
import QShareInstallerLinksTable from '../comps/QShareInstallerLinksTable';
import QAddOrganization from '../comps/QAddOrganization';
import QGetOrganizationKey from '../comps/QGetOrganizationKey';
import QDeleteOrganization from '../comps/QDeleteOrganization';
import QAddOrgProduct from '../comps/QAddOrgProduct';
import QDeleteOrgProduct from '../comps/QDeleteOrgProduct';
import QToggleOrgActivate from '../comps/QToggleOrgActivate';
import QGenerateNewOrgKey from '../comps/QGenerateNewOrgKey';
import QUpdateOrgEmailSuffix from '../comps/QUpdateOrgEmailSuffix';
import QAddProductRelease from '../comps/QAddProductRelease';
import  QDeleteProductRelease from '../comps/QDeleteProductRelease';
import  QUpdateProductRelease from '../comps/QUpdateProductRelease';
import axios from 'axios';

import  '../css/QDownloads.css'; 
// import logo from  '../images/boydlogo.png';
// import { Redirect } from 'react-router-dom';

const boxStyle =  {
    maxWidth: "1000px",
    margin: "auto",
    padding:"20px"
}

// main root class for QAdmin page
class QAdmin extends React.Component {

    constructor(props) {
    super(props);
    this.state = { userName: null, isLogged:false, companyName:null, email: null, organizations:[], installerLinks:[], activeIndex:-1, products:[]}
    this.tokenTimeOut = null;
    }

    // get User Password request form 
    getUserForm(){
    let body = new FormData();
    body.append("username", this.state.email);
    body.append("password", this.state.userName);
    return body;
    }

    // handle company key and email submit button click
    submitHandler = (event) => {
    let result = axios.post('/user/login', this.getUserForm())

    result.then((response) => {this.saveToken(response.data.access_token, response.data.token_validity * 1000)},
                (error) => alert(error.response.data.detail))  // Server error response

    }


    // This method is called during componnet construction. Check for valid saved browser token in the browser local storage and get info from server.
    componentDidMount() {  
      if (this.getToken()){
        let tokenValidity = JSON.parse(localStorage.getItem("authenticationToken"))["expiryTime"] - new Date().getTime()
        if (tokenValidity > 0) {
        this.tokenTimeOut = setTimeout(() => {this.clearToken()}, tokenValidity)
        }
        // this.setState({isLogged : true});
        // this.getUser();
        this.getOrganizations();
        this.getProducts();
        this.getInstallerLinks();
        
      }
    }

    // This method is called when any component props or state is changed.
    componentDidUpdate(prevProps, prevState) {
      // if isLogged is changed  and it is true, get organisation and available downloads
      if (this.state.isLogged !== prevState.isLogged) {
        if (this.state.isLogged && this.getToken())
          { this.getUser();
            this.getOrganizations();
            this.getProducts();
            this.getInstallerLinks();
          }
      }
    }

    saveToken = (token, tokenValidity) => {  // Save the token in the browser local storage
      const expiryTime = new Date().getTime() + tokenValidity
      let tokenData =  {"token":token, "expiryTime":expiryTime}
      localStorage.setItem("authenticationToken", JSON.stringify(tokenData))
      this.tokenTimeOut = setTimeout(() => {this.clearToken()}, tokenValidity)
      this.setState({isLogged: true})
    }

    getToken () {  // Get token from the local storage.
      try {
      let tokenData = JSON.parse(localStorage.getItem("authenticationToken"))
      return tokenData ? tokenData["token"] : null
      } 
      catch (error) {   // Handle JOSN.parse error with old token string
        this.clearToken()
        return null
      }

    }

    clearToken = () => {  // clear token in the local storage
      localStorage.setItem("authenticationToken", null);
      this.setState({isLogged: false})
      if (this.tokenTimeOut){
        clearTimeout(this.tokenTimeOut)
      }
    }

    // Method to get user organisation name and email from the server
    getUser = () => {
      if (!this.getToken()) {return}
      let result = axios({method: "get", url: "/user/current", params: {token: this.getToken()}})
      result.then((response) => {},
                  (error) => {this.clearToken(); console.log(error.response.data.detail)})
    }


    // Method to get dorganizations data from the server 
    getOrganizations = () => {
        if (!this.getToken()) {return}
        let result = axios({method: "get", url: "/admin/organizations", params: {token: this.getToken()}})
        result.then((response)=> {this.setState({organizations: response.data.organizations, isLogged:true})},
                    (error) => {this.clearToken(); console.log(error.response.data.detail)})
      }

      // Method to get products data from the server
    getProducts = () => {
      if (!this.getToken()) {return}
      let result = axios({method:"get", url:"/admin/products", params: {token: this.getToken()}})
      result.then((response)=> {this.setState({products: response.data.products})},
                  (error) => {this.clearToken(); console.log(error.response.data.detail)})
    }

     // Method to get installer links data from the server 
     getInstallerLinks = () => {
      if (!this.getToken()) {return}
      let result = axios({method: "get", url: "/admin/installer/links", params: {token: this.getToken()}})
      result.then((response)=> {this.setState({installerLinks: response.data.links, isLogged:true})},
                  (error) => {this.clearToken(); console.log(error.response.data.detail)})
    }

      
      handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
    
        this.setState({ activeIndex: newIndex})
      }

      organizationNames = () => {
        var orgs =[]
        if (this.state.organizations.length > 0) {
        this.state.organizations.map((org) => (
            orgs.push({key:org[0], value:org[0], text:org[0]})
        ))}
        return orgs
      }

      getOrgEmailSuffixes = (org) => {
        var emailSuffixes=[]
        if (org) {
        for (var i=0; i < this.state.organizations.length; i++) {
          let org_data = this.state.organizations[i]
          if(org_data[0] === org) {
              org_data[4].map((emailSuffix) => 
                  emailSuffixes.push({key:emailSuffix, value:emailSuffix, text:emailSuffix})
              )
              break 
      }
      }
      }
        return emailSuffixes
    }

      productNames = () => {
        var prods = []
        if (this.state.products.length > 0) {
          this.state.products.map((prod) => (
            prods.push({key:prod[0], value:prod[0], text:prod[0]})

          ))
        }
        return prods
      }

    render() {
        if (!this.state.isLogged)
        {
        return (    // Render Login page to submit company key and email
          <div className="box">
              {/*<Image src={logo} centered size='small' />*/}
            <Form>
              <Form.Field >
                <label>Email</label>
                <input placeholder='Email' onChange={(event) => this.setState({email: event.target.value})} />
              </Form.Field>
              <Form.Field>
                <label>Company key</label>
                <input type="password" placeholder='Company key' onChange={(event) => this.setState({userName: event.target.value})}/>
              </Form.Field>
              <Button className="submitButton" fluid  onClick={(e) => this.submitHandler()}>
                Submit
              </Button>
            </Form>
          </div>
        )}
        else
        {
        
        return ( 
        <div style={boxStyle}>
        <Button className="logoutButton"   onClick={(e) => this.clearToken()} >Logout</Button>
        <QOrganizationTable organizations={this.state.organizations} getToken={this.getToken} getUser={this.getUser} getOrgEmailSuffixes={this.getOrgEmailSuffixes}></QOrganizationTable>
        <QProductTable products={this.state.products} organizationNames={this.organizationNames()} getToken={this.getToken} getUser={this.getUser} getInstallerLinks={this.getInstallerLinks}></QProductTable>
        { this.state.installerLinks.length >  0 ?
          (<QShareInstallerLinksTable links={this.state.installerLinks} getToken={this.getToken} getUser={this.getUser} getInstallerLinks={this.getInstallerLinks}></QShareInstallerLinksTable>) : <></>}
        <Header size='medium' textAlign='left'>Manage Organizations</Header>
        <Accordion>
        <QAddOrganization
            getUser={this.getUser}
            getToken={this.getToken}
            activeIndex = {this.state.activeIndex}
            index = {0}
            handleClick = {this.handleClick}
            getOrganizations = {this.getOrganizations}>
        </QAddOrganization>
        <div style={{marginTop:"10px"}}></div>
        <QAddOrgProduct
            getUser={this.getUser}
            getToken={this.getToken}
            activeIndex = {this.state.activeIndex}
            index = {1}
            handleClick = {this.handleClick}
            getOrganizations = {this.getOrganizations}
            organizationNames = {this.organizationNames()}
            productNames = {this.productNames()}>
        </QAddOrgProduct>
        <div style={{marginTop:"10px"}}></div>
        <QDeleteOrgProduct
            getUser={this.getUser}
            getToken={this.getToken}
            activeIndex = {this.state.activeIndex}
            index = {2}
            handleClick = {this.handleClick}
            getOrganizations = {this.getOrganizations}
            organizationNames = {this.organizationNames()}
            organizations = {this.state.organizations}>
        </QDeleteOrgProduct>
        <div style={{marginTop:"10px"}}></div>
        <QDeleteOrganization
            getUser={this.getUser}
            getToken={this.getToken}
            activeIndex = {this.state.activeIndex}
            index = {3}
            handleClick = {this.handleClick}
            getOrganizations = {this.getOrganizations}
            organizationNames = {this.organizationNames()}>
        </QDeleteOrganization>
        <div style={{marginTop:"10px"}}></div>
        <QToggleOrgActivate
            getUser={this.getUser}
            getToken={this.getToken}
            activeIndex = {this.state.activeIndex}
            index = {4}
            handleClick = {this.handleClick}
            getOrganizations = {this.getOrganizations}
            organizationNames = {this.organizationNames()}>
        </QToggleOrgActivate>
        <div style={{marginTop:"10px"}}></div>
        <QGetOrganizationKey
            getUser={this.getUser}
            getToken={this.getToken}
            activeIndex = {this.state.activeIndex}
            index = {5}
            handleClick = {this.handleClick}
            getOrganizations = {this.getOrganizations}
            organizationNames = {this.organizationNames()}>
        </QGetOrganizationKey>
        <div style={{marginTop:"10px"}}></div>
        <QGenerateNewOrgKey
            getUser={this.getUser}
            getToken={this.getToken}
            activeIndex = {this.state.activeIndex}
            index = {9}
            handleClick = {this.handleClick}
            getOrganizations = {this.getOrganizations}
            organizationNames = {this.organizationNames()}>
        </QGenerateNewOrgKey>
        <div style={{marginTop:"10px"}}></div>
        <QUpdateOrgEmailSuffix
            getUser={this.getUser}
            getToken={this.getToken}
            activeIndex = {this.state.activeIndex}
            index = {10}
            handleClick = {this.handleClick}
            getOrganizations = {this.getOrganizations}
            getOrgEmailSuffixes = {this.getOrgEmailSuffixes}
            organizationNames = {this.organizationNames()}>
        </QUpdateOrgEmailSuffix>
        </Accordion>

        <Header size='medium' textAlign='left'>Manage Products</Header>
        <Accordion>
        <QAddProductRelease
            getUser={this.getUser}
            getToken={this.getToken}
            activeIndex = {this.state.activeIndex}
            index = {6}
            handleClick = {this.handleClick}
            getProducts = {this.getProducts}
            productNames = {this.productNames()}>
        </QAddProductRelease>
        <QDeleteProductRelease
            getUser={this.getUser}
            getToken={this.getToken}
            activeIndex = {this.state.activeIndex}
            index = {7}
            handleClick = {this.handleClick}
            getProducts = {this.getProducts}
            productNames = {this.productNames()}
            products = {this.state.products}>
        </QDeleteProductRelease>
        <QUpdateProductRelease
         getUser={this.getUser}
         getToken={this.getToken}
         activeIndex = {this.state.activeIndex}
         index = {8}
         handleClick = {this.handleClick}
         getProducts = {this.getProducts}
         productNames = {this.productNames()}
         products = {this.state.products}>
        </QUpdateProductRelease>
        </Accordion> 
        </div>
        )}

    }
}


export default  QAdmin;