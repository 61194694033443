import React from 'react';
import {Form, Label, Segment, Accordion, Dropdown, TextArea, Button} from 'semantic-ui-react';
import axios from 'axios';
import '../css/QAdminAPI.css'

const activeOptions = [
    { key: 'true', text: 'True', value:'true'},   
    { key: 'false', text: 'False', value:'false'},
]

const previewOptions = [
    { key: 'false', text: 'False', value:'false'},
    { key: 'true', text: 'True', value:'true'},   
]

class QUpdateProductRelease extends React.Component {

    constructor(props) {
        super(props);
        this.state = {product:null, version:null, buildNumber:null, activate:'true', response:null, preview:false}
        }

    // Get version Number list of the selected product
    versionNumbers = () => {
        var versions = []
        for (var i=0; i < this.props.products.length; i++) {
            let prod = this.props.products[i]
            if(this.state.product === prod[0]) {
                prod[1].map((release) => versions.push({key:release[0], value:release[0], text:release[0]}))
                break 
        }
        }
        return versions
        }
    
        // Get build Number list of the selected product and version
    buildNumbers = () => {
        var buildNumbers = []
        for (var i=0; i < this.props.products.length; i++) {
            let prod = this.props.products[i]
            if(this.state.product === prod[0]) {
                let releases = prod[1]
                for (var j=0; j < releases.length; j++) {
                    let release = releases[j]
                    if (this.state.version === release[0]) {
                        release[1].map((build) => buildNumbers.push({key:build[0], value:build[0], text:build[0]}))
                    break
                }
                
        }
        break
        }
        }
        return buildNumbers
    } 

    // Sent download link to the user email.
    toggleProductReleaseActivate = () => {
        if (!this.state.product){ 
            alert("Please enter a product.")
            return
        }
        if (!this.state.version){ 
            alert("Please define a version.")
            return
            }
        if (!this.state.buildNumber){ 
        alert("Please enter a build number")
        return
        }
        this.setState({response: ""})
        const url = "/admin/update/" + this.state.product + "/" + this.state.version
        const params = {build_number: this.state.buildNumber, active:this.state.activate, preview: this.state.preview, token:this.props.getToken()}
        let result = axios({method: "put", url:url, params: params})
        result.then((response)=> {this.setState({response: response.data.response}); this.props.getProducts()},
                 (error) => {this.setState({response: error.response.data.detail}); this.props.getUser()})

    }

    render () {
        return (
            <Segment  className='putSegmentStyle'>
            <Accordion.Title 
            active={this.props.activeIndex === this.props.index}
            content= {(
                <>
                <Label className='methodLabel' color='orange'> Update a given product release </Label>
                </>
                )} 
                index={this.props.index}
                onClick={this.props.handleClick}
            />
            <Accordion.Content
            active={this.props.activeIndex === this.props.index} 
            content={(
            <>
            <Form>
                <Form.Field >
                <label>Product</label>
                <Dropdown placeholder='Select product' selection options={this.props.productNames}
                onChange={(event, {name, value}) => this.setState({product: value})}/>
                </Form.Field>
                <Form.Field >
                <label>Version</label>
                <Dropdown placeholder='Select version' selection options={this.versionNumbers()}
                onChange={(event, {name, value}) => this.setState({version: value})}/>
                </Form.Field>
                <Form.Field >
                <label>Build Number</label>
                <Dropdown placeholder='Select build number' selection options={this.buildNumbers()}
                onChange={(event, {name, value}) => this.setState({buildNumber: value})}/>
                </Form.Field>
                <Form.Field >
                <label>Activate</label>
                <Dropdown placeholder='Active'  selection options={activeOptions} defaultValue={this.state.activate} 
                onChange={(event, {name, value}) => this.setState({activate: value})}/>
                </Form.Field>
                <Form.Field >
                <label>Preview version</label>
                <Dropdown placeholder='Preview'  selection options={previewOptions} defaultValue={previewOptions[0].value} 
                onChange={(event, {className, value}) => this.setState({preview: value})}/>
                </Form.Field>
                <Form.Field>
                <Button className="submitBtn" fluid onClick={(e) => this.toggleProductReleaseActivate()}>
                Submit
                </Button>
                </Form.Field> 
                <Form.Field>
                <label>Response</label>
                <TextArea value={this.state.response}/>
                </Form.Field>     
            </Form>
            </>
            )} />
            </Segment>
        )
    }

}

export default QUpdateProductRelease