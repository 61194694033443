import React from 'react';
import {Form, Label, Segment, Accordion, Dropdown, TextArea, Button} from 'semantic-ui-react';
import axios from 'axios';
import '../css/QAdminAPI.css'


const roleOptions = [
    { key: 'user', text: 'User', value:'user'},
    { key: 'admin', text: 'Admin', value:'admin'},
]

class QAddOrganization extends React.Component {

    constructor(props) {
        super(props);
        this.state = {organization:null, role:'user', emailSuffixes:null, response:null}
        }

    // Sent download link to the user email.
  addOrganization = () => {
    if (!this.state.organization){ 
    alert("Please enter an organization.")
    return
    }
    if (!this.state.emailSuffixes){ 
        alert("Please enter email suffix(es).")
        return
    }
    this.setState({response: ""})
    const url = "/admin/" + this.state.organization // /link/Qfin/6.2

    const params = {email_suffixes: this.state.emailSuffixes, role:this.state.role, token:this.props.getToken()}
    let result = axios({method: "post", url:url, params: params})
    result.then((response)=> {this.setState({response: response.data.response}); this.props.getOrganizations()},
                 (error) => {this.setState({response: error.response.data.detail}); this.props.getUser()})

  }

    render () {
        return (
            <Segment  className='postSegmentStyle'>
            <Accordion.Title 
            active={this.props.activeIndex === this.props.index}
            content= {(
                <>
                <Label className='methodLabel' color='green'> Add a new organization </Label>
                </>
                )} 
                index={this.props.index}
                onClick={this.props.handleClick}
            />
            <Accordion.Content
            active={this.props.activeIndex === this.props.index} 
            content={(
            <>
            <Form>
                <Form.Field >
                <label>Organization</label>
                <input placeholder='Organization'  onChange={(event) => this.setState({organization: event.target.value})}/>
                </Form.Field>
                <Form.Field >
                <label>Email suffixes</label>
                <input placeholder='Email suffixes by ;'  onChange={(event) => this.setState({emailSuffixes: event.target.value})}/>
                </Form.Field>
                <Form.Field >
                <label>Role</label>
                <Dropdown placeholder='Role'  selection options={roleOptions} defaultValue={roleOptions[0].value} 
                onChange={(event, {className, value}) => this.setState({role: value})}/>
                </Form.Field>
                <Form.Field>
                <Button className="submitBtn" fluid onClick={(e) => this.addOrganization()}>
                Submit
                </Button>
                </Form.Field> 
                <Form.Field>
                <label>Response</label>
                <TextArea value={this.state.response}/>
                </Form.Field>     
            </Form>
            </>
            )} />
            </Segment>
        )
    }

}

export default QAddOrganization