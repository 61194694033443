import React from 'react';

import { Icon } from 'semantic-ui-react';
import '../../css/Qshare.css'

class QShareDownloadInfo extends React.Component  {

    constructor(props) {
        super(props);
        this.state = { productVersion: null};
    }

    componentDidMount() {  
        const shareUrl = localStorage.getItem("iShareUrl")
        if (!shareUrl || shareUrl === 'null'){
            window.open("/", "_self")
        }
        else {
            const productVersion = localStorage.getItem("iProductVersion") 
            this.setState({ productVersion: productVersion})
            window.open(shareUrl, "_self")
            localStorage.setItem("iShareUrl", null)  
            localStorage.setItem("iProductVersion", null)    
        }
      }

    render() {
        return (     
            <label className="infoBox">
                <Icon name='download' size='large' /> {this.state.productVersion} Installer
            </label>             
        )
    }

    

}

export default QShareDownloadInfo;