import React from 'react';
import {Icon} from 'semantic-ui-react';
import axios from 'axios';

const iconStyle = {
    cursor:"pointer"
}
class QshowKey  extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show:false, key:null}
    }

    showHideKey () {
        if (!this.state.show) {
            const url = "/admin/organization_key" // /link/Qfin/6.2
            const params = {organization: this.props.orgName, token:this.props.getToken()}
            let result = axios({method: "get", url:url, params: params})
            result.then((response)=> {this.setState({key: response.data.response, show:true})},
                 (error) => {this.setState({response: error.response.data.detail}); this.props.getUser()})
        }
        else {
            this.setState({show:false})
        }

    }

    copyKey () {
        navigator.clipboard.writeText(this.state.key)
    }

    render() { 
        return (
            <>
            <Icon 
            title={this.state.show === true ? "Hide key" : "Show key"} 
            name={this.state.show === true ? "unhide" : "hide"} size="large"  
            style={iconStyle} 
            onClick={(e) => this.showHideKey()}/>

            {this.state.show === true ? 
            (<label>{this.state.key}<Icon name="copy" title="copy" size="large" style={iconStyle} onClick={(e) => this.copyKey()}/> </label>):
            (<label></label>)} 
            </>
            
          );
    }
}
 
export default QshowKey ;