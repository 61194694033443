import React from 'react';
import {Icon, Modal, Button, Form, Label, Dropdown} from 'semantic-ui-react';
import axios from 'axios';

import  '../css/QDownloads.css'; 

const iconStyle = {
    cursor:"pointer"
}

const emailOptions = [
  { key: 'product_user', text: 'Product users', value:'product_user'},
  { key: 'org_user', text: 'Organization users', value:'org_user'},
  { key: 'specific_user', text: 'Specific users', value:'specific_user'}
]


class QSendProductVersionEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {toEmail:null, showModalDialog:false, sendEMailTo:'specific_user', organization:null}
    }

    openEMailDialog () {
        this.setState({showModalDialog:true})
    }

    send_email () {
      // if (!this.state.toEmail){ 
      //   alert("Please enter To email addresses.")
      //   return
      //   }
      
        this.setState({showModalDialog:false})
        const url = "/admin/send_new_version_email" // /link/Qfin/6.2  /admin/
        var params;

        if (this.state.sendEMailTo === "specific_user") {
          params = {product:this.props.product, version:this.props.version, send_to_option:this.state.sendEMailTo, org:'', build_number:this.props.buildNumber, to_emails:this.state.toEmail, token:this.props.getToken()};
        } else if (this.state.sendEMailTo === "org_user") {
          params = {product:this.props.product, version:this.props.version, send_to_option:this.state.sendEMailTo, org:this.state.organization, build_number:this.props.buildNumber, to_emails:'', token:this.props.getToken()}
        } else {
          params = {product:this.props.product, version:this.props.version, send_to_option:this.state.sendEMailTo, org:'', build_number:this.props.buildNumber, to_emails:'', token:this.props.getToken()};
        }

        let result = axios({method: "get", url:url, params: params})
            result.then((response)=> {alert("Email Sucessfully sent.")},
                 (error) => {alert(error.response.data.detail); this.props.getUser()})

        // this.setState({showModalDialog:true})
    }

    render() { 
        const prodFullName = this.props.product + " " + this.props.version + "." + this.props.buildNumber;
        return (
            <> 
            <Icon 
            title="Click to send new version email."
            name="mail" size="large"  
            style={iconStyle} 
            onClick={(e) => this.openEMailDialog()}/>
            <Modal
              closeIcon
              open={this.state.showModalDialog}
              size='small'
              onClose={() => this.setState({showModalDialog:false})}
              onOpen={() => this.setState({showModalDialog:true})}
            >
              <Modal.Header className="modalHeader">
                <label>Send new version email</label>
                <Label className="installerLabel"><Icon  name='product hunt' />{prodFullName}</Label>
              </Modal.Header>
              <Modal.Content className="modalContent">   
              <Form>
                <Form.Field>
                  <label>Send Email to</label>
                  <Dropdown placeholder='Send Email to'  selection options={emailOptions} defaultValue={this.state.sendEMailTo} 
                  onChange={(event, {className, value}) => this.setState({sendEMailTo: value})}/>
                </Form.Field>
                { this.state.sendEMailTo === "specific_user" ? (
                <Form.Field>
                  <label>To Email(s)</label>
                  <input placeholder='For multiple email addresses use semicolon(;)' onChange={(event) => this.setState({toEmail: event.target.value})} />
                </Form.Field>
                ): (<></>)
                }     
                
                { this.state.sendEMailTo === "org_user" ? (
                  <Form.Field >
                  <label>Organization</label>
                  <Dropdown placeholder='Select organization' selection options={this.props.organizationNames}
                  onChange={(event, {name, value}) => this.setState({organization: value})}/>
                  </Form.Field>
                ): (<></>)
                }
                
                   
              </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() =>this.send_email()}>Send email</Button>
              </Modal.Actions>
            </Modal>
            </>
         );
    }
}
 
export default QSendProductVersionEmail;