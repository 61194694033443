import React from 'react';
import { Table, Icon, Grid, Accordion} from 'semantic-ui-react';
import QSendProductVersionEmail from './QSendProductVersionEmail'
import QGenerateInstallerLink from './QGenerateInstallerLink';

const cellStyle = {
    border: "1px solid #a3a3a3",
}

const cellStyle1 = {
    border: "1px solid #a3a3a3",
    textAlign: "center"
}

const headerCellStyle = {
    border: "1px solid #a3a3a3",
    backgroundColor: "#cce2ff"
}

class QProductTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = index === activeIndex ? -1 : 0
        this.setState({ activeIndex: newIndex })
      }

    render(){
        return(
            <Accordion>
                <Accordion.Title
                    active={this.state.activeIndex === 0}
                    content={<label className="tableHeader" textAlign='left'>Products</label>}
                    index={0}
                    onClick={this.handleClick}
                />
                <Accordion.Content
                    style={{maxWidth: '100%'}} 
                    active={this.state.activeIndex === 0}
                    content={
                        <Table celled structured>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={headerCellStyle} textAlign='center'>Product</Table.HeaderCell>
                                    <Table.HeaderCell style={headerCellStyle} textAlign='center'>Version</Table.HeaderCell>
                                    <Table.HeaderCell style={headerCellStyle} textAlign='center'>Build number</Table.HeaderCell>
                                    <Table.HeaderCell style={headerCellStyle} textAlign='center'>Build date</Table.HeaderCell>
                                    <Table.HeaderCell style={headerCellStyle} textAlign='center'>Build Name</Table.HeaderCell>
                                    <Table.HeaderCell style={headerCellStyle} textAlign='center'>Active</Table.HeaderCell>
                                    <Table.HeaderCell style={headerCellStyle} textAlign='center'>Preview Version</Table.HeaderCell>
                                    <Table.HeaderCell style={headerCellStyle} textAlign='center'>Email</Table.HeaderCell>
                                    <Table.HeaderCell style={headerCellStyle} textAlign='center'>Share</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.props.products.map(prod => (
                                    <>
                                    {prod[1].length > 0 ?
                                        (
                                        <>
                                        {prod[1].map((release, i) => ( 
                                        <>
                                        {i === 0 ?
                                        (
                                        <>
                                        {
                                        release[1].map((build, j) => ( 
                                            <Table.Row>
                                                { j === 0 ? (
                                                <>
                                                <Table.Cell style={cellStyle} rowSpan={prod[2]}>{prod[0]}</Table.Cell>
                                                <Table.Cell style={cellStyle} rowSpan={release[1].length.toString()}>{release[0]}</Table.Cell>
                                                </>
                                                ):
                                                (<></>)
                                                }
                                                <Table.Cell style={cellStyle}>{build[0]}</Table.Cell>
                                                <Table.Cell style={cellStyle}>{build[1]}</Table.Cell>
                                                <Table.Cell style={cellStyle}>{build[2]}</Table.Cell>
                                                <Table.Cell style={cellStyle} textAlign='center'>
                                                    {build[3]=== true ? <Icon color='green' name='checkmark' size='large' />: 
                                                        <Icon color='red' name='close' size='large' />}
                                                </Table.Cell>
                                                <Table.Cell style={cellStyle} textAlign='center'>
                                                    {build[4]=== true ? <Icon color='green' name='checkmark' size='large' />: 
                                                        <Icon color='red' name='close' size='large' />}
                                                </Table.Cell>
                                                <Table.Cell style={cellStyle1}>
                                                    {build[3]=== true ? 
                                                        <>
                                                        <Grid.Column>
                                                            <QSendProductVersionEmail product={prod[0]} version={release[0]} buildNumber={build[0]} organizationNames={this.props.organizationNames} getToken={this.props.getToken} getUser={this.props.getUser}/>
                                                        </Grid.Column>
                                                        </>
                                                        :
                                                        ''
                                                    }
                                                </Table.Cell>
                                                <Table.Cell style={cellStyle1}>
                                                    {build[3]=== true ? <QGenerateInstallerLink product={prod[0]} version={release[0]} buildNumber={build[0]} buildDate={build[1]} getToken={this.props.getToken} getUser={this.props.getUser} getInstallerLinks={this.props.getInstallerLinks}/> : ''
                                                    }
                                                </Table.Cell>
                                            </Table.Row>
                                            ))
                                        } </>):
                                        (
                                        <>
                                        {release[1].map((build, j) => (
                                            <Table.Row> 
                                            { j === 0 ? (
                                                <Table.Cell style={cellStyle} rowSpan={release[1].length.toString()}>{release[0]}</Table.Cell>
                                                ): (<></>)
                                                }
                                                <Table.Cell style={cellStyle}>{build[0]}</Table.Cell>
                                                <Table.Cell style={cellStyle}>{build[1]}</Table.Cell>
                                                <Table.Cell style={cellStyle}>{build[2]}</Table.Cell>
                                                <Table.Cell style={cellStyle} textAlign='center'>
                                                    {build[3]=== true ? <Icon color='green' name='checkmark' size='large' />: 
                                                        <Icon color='red' name='close' size='large' />}
                                                </Table.Cell>
                                                <Table.Cell style={cellStyle} textAlign='center'>
                                                    {build[4]=== true ? <Icon color='green' name='checkmark' size='large' />: 
                                                        <Icon color='red' name='close' size='large' />}
                                                </Table.Cell> 
                                                <Table.Cell style={cellStyle1}>
                                                    { build[3]=== true ? <QSendProductVersionEmail product={prod[0]} version={release[0]} buildNumber={build[0]} organizationNames={this.props.organizationNames} getToken={this.props.getToken} getUser={this.props.getUser}/> : '' }
                                                </Table.Cell>
                                                <Table.Cell style={cellStyle1}>
                                                    { build[3]=== true ? <QGenerateInstallerLink product={prod[0]} version={release[0]} buildNumber={build[0]} buildDate={build[1]} getToken={this.props.getToken} getUser={this.props.getUser} getInstallerLinks={this.props.getInstallerLinks}/> : '' }
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                        </>
                                        )}
                                        </>
                                        ))}
                                        </>)   
                                    :
                                    (
                                    <Table.Row>
                                        <Table.Cell style={cellStyle}>{prod[0]}</Table.Cell>
                                        <Table.Cell style={cellStyle}></Table.Cell>
                                        <Table.Cell style={cellStyle}></Table.Cell>
                                        <Table.Cell style={cellStyle}></Table.Cell>
                                        <Table.Cell style={cellStyle}></Table.Cell>
                                        <Table.Cell style={cellStyle}></Table.Cell>
                                        <Table.Cell style={cellStyle}></Table.Cell>
                                        <Table.Cell></Table.Cell>
                                    </Table.Row> 
                                    )}
                                    </>
                                ))}      
                            </Table.Body>
                        </Table>
                    }
                />
            </Accordion>
        )
    }
}

export default QProductTable