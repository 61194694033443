import React from 'react';
import {Form, Label, Segment, Accordion, TextArea, Button, Dropdown} from 'semantic-ui-react';
import axios from 'axios';
import '../css/QAdminAPI.css'

class QDeleteOrgProduct extends React.Component {

    constructor(props) {
        super(props);
        this.state = {organization:null, product:null, response:null}
        }

    // Sent download link to the user email.
    deleteOrgProduct = () => {
        if (!this.state.organization){ 
        alert("Please enter an organization.")
        return
        }
        if (!this.state.product){ 
            alert("Please enter a product.")
            return
        }
        this.setState({response: ""})
        const url = "/admin/" + this.state.organization + "/" + this.state.product
        const params = {token:this.props.getToken()}
        let result = axios({method: "delete", url:url, params: params})
        result.then((response)=> {this.setState({response: response.data.response}); this.props.getOrganizations()},
                    (error) => {this.setState({response: error.response.data.detail}); this.props.getUser()})

    }

    // Get product access list of the selected organization
    orgProductNames = () => {
        var prods = []
        for (var i=0; i < this.props.organizations.length; i++) {
            let org = this.props.organizations[i]
            if(this.state.organization === org[0]) {
                org[3].map((prod) => prods.push({key:prod[0], value:prod[0], text:prod[0]}))
                break 
            }
        }
        return prods
      }


    render () {
        return (
            <Segment  className='deleteSegmentStyle'>
            <Accordion.Title 
            active={this.props.activeIndex === this.props.index}
            content= {(
                <>
                <Label className='methodLabel' color='red'> Delete an organization product access info </Label>
                </>
                )} 
                index={this.props.index}
                onClick={this.props.handleClick}
            />
            <Accordion.Content
            active={this.props.activeIndex === this.props.index} 
            content={(
            <>
            <Form>
                <Form.Field >
                <label>Organization</label>
                <Dropdown placeholder='Select organization' selection options={this.props.organizationNames}
                onChange={(event, {name, value}) => this.setState({organization: value})}/>
                </Form.Field>
                <Form.Field>
                <Form.Field >
                <label>Product</label>
                <Dropdown placeholder='Select product' selection options={this.orgProductNames()}
                onChange={(event, {name, value}) => this.setState({product: value})}/>
                </Form.Field>
                <Button className="submitBtn" fluid onClick={(e) => this.deleteOrgProduct()}>
                Submit
                </Button>
                </Form.Field> 
                <Form.Field>
                <label>Response</label>
                <TextArea value={this.state.response}/>
                </Form.Field>     
            </Form>
            </>
            )} />
            </Segment>
        )
    }

}

export default QDeleteOrgProduct