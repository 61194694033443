import React from 'react'
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom'
import Home from './pages/Home'
import Qdownloads from './pages/QDownloads'
import QAdmin from './pages/QAdmin'
import QShare from './pages/share/QShare'
import QShareDownloadError from './pages/share/QShareDownloadError.js'
import QShareInfo from './pages/share/QshareDownloadInfo'

function App() {
    return (

        <Router>
            <Switch>  
                <Route path='/qdownloads'  component={Qdownloads}/>
                <Route path='/admin'  component={QAdmin}/>
                <Route path='/share-download/:shareId'  component={QShare}/>
                <Route path='/share-info'  component={QShareInfo}/>
                <Route path='/share-error'  component={QShareDownloadError}/>
                <Route path='/' component={Home}/>
            </Switch> 
        </Router>

    )
}

export default App