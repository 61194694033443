import React from 'react';
import {Portal, Segment, Icon} from 'semantic-ui-react';

import '../css/QDownloads.css'; 

const iconStyle = {
    cursor:"pointer",
    color:"#89c5f3"
}
class QHelpGuide extends React.Component {

    constructor(props) {
        super(props);
        this.state = {open:false, xPos:"0.0px", yPos:"0.0px"}
    }

    handleOpen = () => {
        this.setState({ open: true })
        }
    
    handleClose = () => {
        this.setState({ open: false })
    }
          
    render() { 
        const open = this.state.open;
        const xPos = this.state.xPos;
        const yPos = this.state.yPos;
        const label = this.props.value;

        return ( 
      <>
      <Portal
            closeOnTriggerClick
            openOnTriggerMouseEnter
            openOnTriggerClick
            trigger={
              <Icon name="question circle" style={iconStyle} size="large"
                content={open ? 'Close Portal' : 'Open Portal'}
                negative={open}
                positive={!open}
                onMouseEnter={(e) => {const rect = e.target.getBoundingClientRect(); this.setState({xPos:rect.x.toString()+"px", yPos:rect.y.toString()+"px"})}}

              />
            }
            onOpen={this.handleOpen}
            onClose={this.handleClose}
          >
            <Segment
              style={{    
                left: xPos,
                top:yPos,
                position: 'fixed',
                width:'320px',
                padding:'10px',
                zIndex: 10000,
              }}
            >
              <label className="helpGuideLabel">{label}</label>
            </Segment>
          </Portal>
      </>
        );
    }
}
 
export default QHelpGuide;