import React from 'react';
import axios from 'axios';
import { Form } from 'semantic-ui-react';

import  '../../css/QDownloads.css'; 

class QShare extends React.Component  {

    constructor(props) {
        super(props);
        this.state = { validId: false, shareId: props.match.params.shareId, email:null, company: null, firstName: null, lastName: null, shareUrl: null };
    }

    componentDidMount() {  
      const shareId = this.state.shareId;
      
      if (shareId) {
          const url = "/share/link_auth"
          const params = { token: shareId }
          const response = axios({method: "get", url:url, params: params});
          response.then((result) => {this.setState({'validId': true})}, (error) => {window.location.href = "../share-error";})    
      }        
      else {
        window.location.href = "../share-error"    
      }
    }

    // handle company key and email submit button click
    submitHandler = async(event) => {
        //eslint-disable-next-line
        const pattern = /^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/gm;
        if (!pattern.test(this.state.email)) {
          alert("Please enter a valid email.")
          return
        }
        if (!this.state.company) {
          alert('Please enter your company name.')
          return
        }
        if (!this.state.firstName) {
          alert('Please enter your first name.')
          return
        }
        if(!this.state.lastName) {
          alert('Please enter your last name.')
          return
        }
        
        try {
            this.setState({shareUrl: null})
            const url = "/share/link"
            const params = {email: this.state.email, company: this.state.company, first_name: this.state.firstName, last_name: this.state.lastName, token: this.state.shareId }
            const  response =  await axios({method: "get", url:url, params: params})
            localStorage.setItem("iShareUrl", response.data.url)
            localStorage.setItem("iProductVersion", response.data.product)
            window.location.href = "../share-info";
        } catch (error) {
            console.error(error);
            window.location.href = "../share-error"
        }        
    }
    

    render() {

      if (this.state.validId) {  
        return (
          <div className="box">
            <p style={{textAlign: 'center'}}>To download installer, please enter your details and click submit button</p>
            <Form style={{marginTop: '10px'}}>
              <Form.Field >
                <label>Email</label>
                <input placeholder='Email' value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} />
              </Form.Field>
              <Form.Field>
                <label>Company</label>
                <input placeholder='Company name' value={this.state.company} onChange={(event) => this.setState({company: event.target.value})}/>
              </Form.Field>
              <Form.Field>
                <label>First name</label>
                <input placeholder='First name' value={this.state.firstName} onChange={(event) => this.setState({firstName: event.target.value})}/>
              </Form.Field>
              <Form.Field>
                <label>Last name</label>
                <input placeholder='Last name' value={this.state.lastName} onChange={(event) => this.setState({lastName: event.target.value})}/>
              </Form.Field>
              <button type='reset' className="fluid ui button"  onClick={(e) => this.submitHandler()}>
                Submit
              </button>
            </Form>
          </div>
        )} else {
          return (<></>)   
        }
    }
}

export default QShare;